<template>
  <v-app class="grey lighten-4">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <v-card
        elevation="0"
        rounded="lg"
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="main-content-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="border-right image-div">
              <h2 class="text-primary-color">{{ $t("qrate") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/two_factor_authentication.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2 login-main-div">
              <div class="w-100 m-auto p-2">
                <h2
                  class="text-center text-primary-color header-main-div font-weight-bold"
                >
                  {{ $t("createPassword") }}
                </h2>
                <br />

                <v-alert
                  v-if="alertMsg.status"
                  :timeout="alertMsg.timeout"
                  :color="alertMsg.color"
                  :type="alertMsg.type"
                  dismissible
                >
                  {{ alertMsg.text }}
                </v-alert>

                <form class="mb-4">
                  <p class="text-midgray-color text-center">
                    {{ $t("createPasswordText") }}
                  </p>
                  <div
                    class="m-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-overlay absolute :value="isOTPVerified">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>

                  <div>
                    <v-text-field
                      v-model="password"
                      :append-icon="
                        isPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isPwdVisible = !isPwdVisible)"
                      :type="isPwdVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      placeholder="Enter New Password"
                      :error-messages="passwordErrors"
                      clearable
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                      :class="{ 'is-invalid': $v.password.$error }"
                    ></v-text-field>

                    <v-text-field
                      v-model="cpassword"
                      :append-icon="
                        isCPwdVisible
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isCPwdVisible = !isCPwdVisible)"
                      :type="isCPwdVisible ? 'text' : 'password'"
                      :label="$t('cpassword')"
                      placeholder="Re-Enter New Password"
                      :error-messages="cpasswordErrors"
                      clearable
                      @input="$v.cpassword.$touch()"
                      @blur="$v.cpassword.$touch()"
                      :class="{ 'is-invalid': $v.cpassword.$error }"
                    ></v-text-field>

                    <v-btn
                      block
                      rounded
                      color="primary"
                      @click="handleSubmit"
                      class="btn-primary mt-4 text-capitalize"
                      >{{ $t("Submit") }}</v-btn
                    >
                  </div>

                  <!-- <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>{{ $t("back_to") }}</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div> -->
                </form>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
        :height="100"
        :width="100"
      />
    </section>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { webbreakdown } from "../../utils/breakpoints";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
export default {
  name: "UpdatePassword",
  mixins: [alerts],
  components: {
    Loading,
  },
  data() {
    return {
      webbreakdown,
      isPwdVisible: false,
      isCPwdVisible: false,
      currentPassword: 0,
      password: "",
      cpassword: "",
      submitDetailsError: false,
      errorMessage: "",
      successMessage: "",
      loader: false,
      receivedMobile: "",
      isOtpValid: false,
      user_email_from_router: this.$route.params.email,
      dismissCountDown: 20,
      dismissCountDownSuccess: 0,
      showDismissibleAlert: false,
      isOTPVerified: false,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      timerCount: 0,
    };
  },
  validations() {
    return {
      currentPassword: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      cpassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
    };
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Must have atleast 6 characters.");
      !this.$v.password.validPassowrd &&
        errors.push(
          "Password must contains Uppercase, Lowercase, Number, Special characters."
        );
      return errors;
    },
    cpasswordErrors() {
      const errors = [];
      if (!this.$v.cpassword.$dirty) return errors;
      !this.$v.cpassword.required && errors.push("Password is required");
      !this.$v.cpassword.minLength &&
        errors.push("Must have atleast 6 characters.");
      !this.$v.cpassword.sameAsPassword &&
        errors.push("Passwords must be same.");
      !this.$v.cpassword.validPassowrd &&
        errors.push(
          "Password must contains Uppercase, Lowercase, Number, Special characters."
        );
      return errors;
    },
  },
  mounted() {
    this.receivedMobile = this.$route.query.mobile;
    console.log(this.receivedMobile);
    this.timerCount = 300;
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && !this.isOTPVerified) {
          // Time interval to run every 1 sec.
          setTimeout(() => {
            // stop interval when time out reaches to zero.
            if (this.timerCount === 0) return;
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.loader = true;
        const changeData = {
          user_name: this.receivedMobile,
          password: this.password,
        };
        const responseData = await userservice.changePassword(changeData);
        console.log("responseData", responseData);
        if (responseData && responseData.data.success) {
          console.log("responseData in", responseData);
          this.showAlertSuccess(responseData.data.message);
          // this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.text = responseData.data.message;
          // this.alertMsg.type = "success";
          // this.alertMsg.color = "green";
          setTimeout(() => {
            this.$router.push({
              name: "Login",
              params: { mobile_number: this.receivedMobile },
            });
          }, 1500);
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
          // this.alertMsg.status = true;
          // this.alertMsg.text = "Password cannot be same or already used!";
          // this.alertMsg.type = "error";
          // this.alertMsg.color = "warning";
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError("something went wrong please try again sometime!");
        // this.alertMsg.status = true;
        // this.alertMsg.text = "Password cannot be same or already used!";
        // this.alertMsg.type = "error";
        // this.alertMsg.color = "warning";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
.logo_img {
  max-height: 50px;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
</style>
